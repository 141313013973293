import { Theme } from '@material-ui/core'
import { CSSProperties, CreateCSSProperties } from '@material-ui/styles'

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletM: '894px',
  laptop: '1024px',
  laptopM: '1200px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`, // primary breakpoint
  tabletM: `(min-width: ${size.tabletM})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopM: `(min-width: ${size.laptopM})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export const sizes = {
  appBarBig: 64,
  appBarMed: 56,
  appBarSmall: 48,
}

export const toolbarClassSize = (theme: Theme): CSSProperties | CreateCSSProperties<{}> => ({
  minHeight: sizes.appBarMed,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    minHeight: sizes.appBarSmall,
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: sizes.appBarBig,
  },
})

export const contentHeight = (theme: Theme): CSSProperties | CreateCSSProperties<{}> => ({
  height: `calc(100vh - ${sizes.appBarMed}px)`,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    height: `calc(100vh - ${sizes.appBarSmall}px)`,
  },
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - ${sizes.appBarBig}px)`,
  },
})
