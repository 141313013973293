/* eslint-disable no-useless-escape */

type SearchParamType = {get: (name: string) => string}

const URLSearchParams = (urlString: string): SearchParamType => {
  const get = (name: string): string => {
    const results = new RegExp(`[\?&]${name}=([^&#]*)`).exec(urlString)
    if (results == null) {
      return null
    }

    return decodeURI(results[1])
  }
  return {
    get,
  }
}

export default { URLSearchParams }
