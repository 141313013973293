
import { createMuiTheme } from '@material-ui/core/styles'
import { getThemeFromApi } from '../services/organization.service'
import { AppTheme } from '../models/organization_theme'
import { getLocalStorage } from '../utils/local_storage/theme.storage'
import loggingService from '../services/logging.service'
// Create a theme instance.
export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#606dbb',
      main: '#3949ab',
      dark: '#273377',
      contrastText: '#fff',
    },
    secondary: {
      light: '#33a7ee',
      main: '#0091ea',
      dark: '#0065a3',
      contrastText: '#000',
    },
    background: {
      default: '#fefefe',
    },
  },
})

export const getOrganizationTheme = async (organization_id?: string): Promise<AppTheme> => {
  try {
    return await getThemeFromApi({ themeId: organization_id, logo: {}, themeOptions: defaultTheme })
  } catch (err) {
    const { THEME, ...items } = getLocalStorage()
    if (err.name === 'NetworkError') throw err
    await loggingService.logAppState({ err, ...items, THEME, source: 'theme' })
    throw new Error('API Failed to retrieve theme. Try again later.')
  }
}
