import React, { useReducer, createContext, ReactElement, Dispatch } from 'react'
import { MeAppUser } from '../models/app_user'
import { AppTheme } from '../models/organization_theme'
import { deleteToken, getToken, setToken } from '../utils/local_storage/login.storage'

export type SplashScreenData = {
  visible: boolean,
  displayText: string,
  showProgress?: boolean,
  showButton?: boolean,
  error?: unknown
}
export type GlobalDataState = {
  currentUser: MeAppUser | null
  jwtToken: string | null
  splashScreen: SplashScreenData
  theme?: AppTheme
}

export type Action =
| { type: 'SET_USER', user: MeAppUser }
| { type: 'SET_SPLASH', splashScreen: SplashScreenData }
| { type: 'SET_THEME', theme: AppTheme }
| { type: 'LOGIN', jwtToken: string }
| { type: 'LOGOUT' }

export const initialState: GlobalDataState = {
  currentUser: null,
  jwtToken: null,
  splashScreen: { visible: false, displayText: '', showProgress: true },
  theme: null,
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const initialContextState: [GlobalDataState, Dispatch<Action>] = [initialState, (action): void => {}]

export const GlobalContext = createContext(initialContextState)

const reducer = (state: GlobalDataState, action: Action): GlobalDataState => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        currentUser: action.user,
      }
    case 'SET_SPLASH':
      return {
        ...state,
        splashScreen: action.splashScreen,
      }
    case 'SET_THEME':
      return {
        ...state,
        theme: action.theme,
      }
    case 'LOGIN':
      setToken(action.jwtToken)
      return {
        ...state,
        jwtToken: action.jwtToken,
      }
    case 'LOGOUT':
      deleteToken()
      return {
        ...state,
        jwtToken: null,
      }
    default:
      throw new Error()
  }
}


type ContextProps = {
  children: React.ReactNode
}

export const GlobalContextProvider = ({ children }: ContextProps): ReactElement => {
  if (typeof window !== 'undefined') initialState.jwtToken = getToken() // for each page that loads, we need to bring in the token

  const [globalState, globalDispatch] = useReducer(reducer, initialState)
  return (
    <GlobalContext.Provider value={[globalState, globalDispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}
