import { AppThemeOptions } from '../../models/organization_theme'

const THEME_KEY = 'THEME'

export function setTheme(theme: AppThemeOptions): void {
  getLocalStorage().setItem(THEME_KEY, JSON.stringify(theme))
}

export function getTheme(): AppThemeOptions | undefined {
  try {
    return JSON.parse(getLocalStorage().getItem(THEME_KEY))
  } catch (_) {
    return undefined // if parse fails, return nothing
  }
}

export function deleteTheme(): void {
  getLocalStorage().removeItem(THEME_KEY)
}

export function getLocalStorage(): Storage {
  return window.localStorage
}
