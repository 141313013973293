import axios from '../utils/axios'

export type FeatureFeedback = {
  name: string,
  email: string,
  feedback: string,
  date: string
}

export type FeedbackResponse = {result: string, row: number}

export const sendFeedback = async (featureFeedback: FeatureFeedback): Promise<FeedbackResponse> => {
  return await axios.post('feedback', featureFeedback) as FeedbackResponse
}
