import React, { ReactElement, useState } from 'react'
import { makeStyles, Fab, Tooltip } from '@material-ui/core'
import FeedbackIcon from '@material-ui/icons/Feedback'
import SuggestionDialogComponent from './suggestion-dialog.component'


const useStyles = makeStyles(() => ({
  feedbackButton: {
    position: 'fixed',
    zIndex: 1101,
    bottom: '10px',
    right: '10px',
  },
  feedbackSection: {
    height: '50vh',
    width: '100vw',
  },
}))
export const FeedbackComponent = (): ReactElement => {
  const classes = useStyles()
  // const [globalState, globalDispatch] = useContext(GlobalContext)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)

  return (
    <>
      <Tooltip title="Feedback">
        <Fab color="primary" aria-label="icon" className={classes.feedbackButton} onClick={(): void => (setIsFeedbackOpen(true))}>
          <FeedbackIcon />
        </Fab>
      </Tooltip>
      <SuggestionDialogComponent open={isFeedbackOpen} handleCancel={(): void => setIsFeedbackOpen(false)} handleSubmit={(): void => setIsFeedbackOpen(false)} />
    </>
  )
}
