import React, { ReactElement, useContext, useState } from 'react'
import { navigate } from 'gatsby'
import { makeStyles, Theme } from '@material-ui/core/styles'


import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import LogoutIcon from '@material-ui/icons/ExitToApp'

import { IconButton } from '@material-ui/core'
import { toolbarClassSize, contentHeight } from '../styles/sizes'
import { RoundedContentComponent } from './rounded-content.component'
import { AppContentComponent } from './app/app-content.component'
import { deleteTheme } from '../utils/local_storage/theme.storage'
import { GlobalContext } from '../contexts/global.store'
import { getLogoUrl, getDefaultLogoUrl } from '../utils/logo'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    ...toolbarClassSize(theme),
  },
  toolbarSpacer: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    ...toolbarClassSize(theme),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 56,
    padding: theme.spacing(0, 1),
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  logo: {
    cursor: 'pointer',
    height: '34px',
  },
  logout: {
    color: '#FFF',
  },
  main: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    ...contentHeight(theme),
  },

}))

type LayoutProps = {
  children: React.ReactNode
  pageName?: string
}

export default function LayoutComponent({
  children,
  pageName = 'Commission Vision',
}: LayoutProps): ReactElement {
  const classes = useStyles()
  const [globalState, globalDispatch] = useContext(GlobalContext)
  const { theme } = globalState
  const [logoSrc, setLogoSrc] = useState(getLogoUrl(theme?.themeId))

  const logout = (): void => {
    globalDispatch({ type: 'LOGOUT' })
    deleteTheme()
    navigate('/login')
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <img
            src={logoSrc}
            onError={(): void => setLogoSrc(getDefaultLogoUrl())}
            alt="Logo"
            style={{ ...theme?.logo }}
            className={classes.logo}
          />
          <Typography variant="h6" noWrap className={classes.title}>
            {pageName}
          </Typography>
          <IconButton onClick={(): void => logout()} className={classes.logout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
        <RoundedContentComponent />
      </AppBar>
      <AppContentComponent>{children}</AppContentComponent>
    </div>
  )
}
