import React, { Component, ReactElement, ReactNode } from 'react'
import * as Sentry from '@sentry/browser'
import { SplashScreenComponent } from './splash-screen.component'

type ErrorBoundaryProps = {
  children: ReactNode
  error?: Error
  setError: React.Dispatch<React.SetStateAction<Error>>
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps> {
  componentDidCatch(error, errorInfo): void {
    const { props } = this
    props.setError(error)
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render(): ReactElement | ReactNode {
    const { children, error } = this.props
    if (error) {
      // render fallback UI
      return <SplashScreenComponent splashScreenState={{ visible: true, displayText: 'Something went wrong. support@commissionvision.io', showProgress: false, showButton: true, error }} />
    }
    // when there's not an error, render children untouched
    return children
  }
}
