import React, { ReactElement, useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { TextField, makeStyles, CircularProgress } from '@material-ui/core'
import { sendFeedback } from '../../services/feedback.service'
import { MessageContext } from '../../contexts/message.store'
import { GlobalContext } from '../../contexts/global.store'

const useStyles = makeStyles(() => ({
  feedbackContainer: {
    // width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
  },
  feedbackText: {
    width: '100%',
  },
  progress: {
    // position: 'absolute',
    // bottom: '25%',
  },
}))

export type SuggestionDialogProps = {
  open: boolean
  handleSubmit(): void
  handleCancel(): void
}

export default function SuggestionDialogComponent({ open, handleSubmit, handleCancel }: SuggestionDialogProps): ReactElement {
  const [feedback, setSuggestion] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [, messageDispatch] = useContext(MessageContext)
  const [globalState] = useContext(GlobalContext)
  const classes = useStyles()
  const dialogContent = (): ReactElement => {
    return (
      <>
        <TextField
          id="standard-multiline-flexible"
          label="App Suggestions"
          multiline
          rowsMax={4}
          rows={4}
          value={feedback}
          className={classes.feedbackText}
          onChange={(e): void => setSuggestion(e.target.value)}
        />
      </>
    )
  }

  const submitClicked = async (): Promise<void> => {
    if (!feedback) return
    setIsLoading(true)
    const { currentUser } = globalState
    try {
      await sendFeedback({ feedback, name: currentUser.name, email: currentUser.email, date: Date.now().toString() })
      messageDispatch({ type: 'SUCCESS', message: 'Thank you for your feedback!' })
    } catch (e) {
      messageDispatch({ type: 'ERROR', message: 'Sending feedback failed. Please try again later!' })
    }
    setIsLoading(false)
    handleSubmit()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">We appreciate your feedback. Tell us what would you like to see in the app!</DialogTitle>
      <DialogContent className={classes.feedbackContainer}>
        {!isLoading ? dialogContent() : (<CircularProgress size={80} className={classes.progress} />)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={submitClicked} color="primary" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
