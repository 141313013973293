import { baseAxios, createNewInstance } from '../utils/axios'

const logAppState = async (appState: unknown): Promise<void> => {
  await createNewInstance().post('logging', appState)
}

const logAppStateNoStorage = async (appState: unknown): Promise<void> => {
  await baseAxios.post('logging_public', appState)
}

export default { logAppState, logAppStateNoStorage }
