import React, { ReactElement, useContext, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { MessageContext } from '../../contexts/message.store'
import { GlobalContext } from '../../contexts/global.store'

export default function AppMessageComponent(): ReactElement {
  const [messageState, messageDispatch] = useContext(MessageContext)
  const [, globalDispatch] = useContext(GlobalContext)
  const logoutUser = (): void => {
    if (messageState.message === 'Unauthorized') globalDispatch({ type: 'LOGOUT' })
  }
  useEffect(logoutUser, [messageState.message])
  return (
    <Snackbar
      open={!!messageState.message}
      autoHideDuration={3000}
      onClose={(): void => {
        messageDispatch({ type: 'CLOSE' })
      }}
    >
      <Alert severity={messageState.severity}>{messageState.message}</Alert>
    </Snackbar>
  )
}
