import React, { ReactElement, useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { contentHeight, toolbarClassSize } from '../../styles/sizes'
import AppMessage from './app-message.component'
import { GlobalContext } from '../../contexts/global.store'
import { FeedbackComponent } from '../feedback/feedback.component'
import { SplashScreenComponent } from '../splash-screen.component'


const useStyles = makeStyles((theme: Theme) => ({
  toolbarSpacer: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    ...toolbarClassSize(theme),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 56,
    padding: theme.spacing(0, 1),
  },
  disableOverflow: {
    overflow: 'hidden',
  },
  main: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      // paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    ...contentHeight(theme),
  },
}))

type AppContentProps = {
  children: React.ReactNode
}

export const AppContentComponent = ({ children }: AppContentProps): ReactElement => {
  const classes = useStyles()

  const [{ splashScreen }] = useContext(GlobalContext)
  return (
    <main className={clsx(classes.main, { [classes.disableOverflow]: splashScreen.visible })}>
      <div className={classes.toolbarSpacer} />
      <FeedbackComponent />
      <SplashScreenComponent splashScreenState={splashScreen} />
      <div className={classes.content}>
        {children}
        <AppMessage />
      </div>
    </main>
  )
}
