const JWT_KEY = 'JWT'

export type JwtResponse = {
  jwt: string,
}
export function isLoggedIn(): boolean {
  return !!getLocalStorage()?.getItem(JWT_KEY)
}

export function setToken(token: string): void {
  getLocalStorage()?.setItem(JWT_KEY, token)
}

export function getToken(): string {
  return getLocalStorage()?.getItem(JWT_KEY)
}


export function deleteToken(): void {
  getLocalStorage()?.removeItem(JWT_KEY)
}

function getLocalStorage(): Storage | undefined {
  return window.localStorage
}
