import React, { ReactElement } from 'react'
import { Theme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  roundedCorners: {
    backgroundColor: '#FFF',
    height: theme.spacing(3),
    width: '100%',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
}))

export const RoundedContentComponent = (): ReactElement => {
  const classes = useStyles()
  return <div className={classes.roundedCorners} />
}
