import React, { useReducer, createContext, ReactElement, Dispatch } from 'react'
import { Color } from '@material-ui/lab/Alert'

export type MessageDataState = {
  message: string
  severity: Color
}

export type Action =
| { type: 'ERROR', message: string }
| { type: 'SUCCESS', message: string }
| { type: 'CLOSE'}

export const initialState: MessageDataState = {
  message: '',
  severity: 'info',
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const initialContextState: [MessageDataState, Dispatch<Action>] = [initialState, (action): void => {}]

export const MessageContext = createContext(initialContextState)

const reducer = (state: MessageDataState, action: Action): MessageDataState => {
  switch (action.type) {
    case 'ERROR':
      return {
        message: action.message,
        severity: 'error',
      }
    case 'SUCCESS':
      return {
        message: action.message,
        severity: 'success',
      }
    case 'CLOSE':
      return {
        message: '',
        severity: 'info',
      }
    default:
      throw new Error()
  }
}


type ContextProps = {
  children: React.ReactNode
}

export const MessageContextProvider = ({ children }: ContextProps): ReactElement => {
  const [messageState, messageDispatch] = useReducer(reducer, initialState)

  return (
    <MessageContext.Provider value={[messageState, messageDispatch]}>
      {children}
    </MessageContext.Provider>
  )
}
