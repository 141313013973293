import { createMuiTheme } from '@material-ui/core'
import { createNewInstance } from '../utils/axios'
import { getTheme, setTheme, deleteTheme } from '../utils/local_storage/theme.storage'
import { AppThemeOptions, OrganizationTheme, toAppThemeOptions, AppTheme } from '../models/organization_theme'

export const getThemeFromApi = async (defaultTheme: AppThemeOptions): Promise<AppTheme> => {
  if (defaultTheme.themeId) deleteTheme() // clear out local storage of existing theme if we get a specific theme requested
  let orgThemeData: AppThemeOptions = getTheme()
  if (!orgThemeData) {
    const res: ApiResponse = await createNewInstance().get(`/organization_theme/${defaultTheme.themeId}`)
    const orgApiTheme = res.data as OrganizationTheme
    if (orgApiTheme) {
      orgThemeData = toAppThemeOptions(orgApiTheme, defaultTheme.themeId)
    }
    setTheme(orgThemeData || defaultTheme)
  }
  return {
    themeId: orgThemeData?.themeId || defaultTheme.themeId,
    logo: orgThemeData?.logo || defaultTheme.logo,
    theme: createMuiTheme(orgThemeData?.themeOptions || defaultTheme.themeOptions),
  }
}
