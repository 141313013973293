import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { makeStyles, CircularProgress, Typography, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { getLogoUrl, getDefaultLogoUrl } from '../utils/logo'
import { SplashScreenData, GlobalContext } from '../contexts/global.store'
import { getLocalStorage } from '../utils/local_storage/theme.storage'
import LoggingService from '../services/logging.service'
import { AppError } from '../utils/axios'


type SplashScreenProps = {
  splashScreenState: SplashScreenData
}

const useStyles = makeStyles((theme: Theme) => ({
  splashPage: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    zIndex: 10000,
    top: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  splashGone: {
    display: 'none',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  logo: {
    width: '256px',
  },
  loginText: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: theme.palette.primary.contrastText,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
    textAlign: 'center',
  },
  supportText: {
    color: theme.palette.primary.contrastText,
    paddingTop: theme.spacing(4),
    width: '300px',
  },
}))

export const SplashScreenComponent = ({ splashScreenState }: SplashScreenProps): ReactElement => {
  const classes = useStyles()

  const { visible, displayText, showProgress, error } = splashScreenState

  const [globalState] = useContext(GlobalContext)
  const { theme } = globalState
  const [logoSrc, setLogoSrc] = useState(getLogoUrl(theme?.themeId))
  const [errorMessage, setErrorMessage] = useState('')

  const sendError = async (): Promise<void> => {
    if ((error as AppError).name === 'NetworkError') {
      setErrorMessage((error as AppError).message)
      return
    }

    try {
      const { THEME, ...items } = getLocalStorage()
      await LoggingService.logAppState({ error, THEME, ...items, source: 'error' })
      setErrorMessage('Details about your error have been reported. The Commission Vision team will address it as soon as possible.')
    } catch (err) { // cookies disabled
      await LoggingService.logAppStateNoStorage({ error, source: 'cookie error' })
      setErrorMessage('It appears that cookies have been disabled. Turn on your browsers cookies for commissionvision to work.')
    }
  }

  useEffect(() => {
    if (visible && error) {
      sendError()
    }
  }, [visible, error])

  return (
    <main className={clsx(classes.splashPage, { [classes.splashGone]: !visible })}>
      <div className={classes.logoSection}>
        <img
          src={logoSrc}
          onError={(): void => setLogoSrc(getDefaultLogoUrl())}
          alt="Logo"
          className={classes.logo}
        />
        <Typography className={classes.loginText} component="h5" variant="h5">{displayText}</Typography>
        <Typography className={classes.supportText}>{errorMessage}</Typography>
        {showProgress && <CircularProgress color="secondary" size={100} />}
      </div>
    </main>
  )
}
