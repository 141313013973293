import axios, { AxiosInstance } from 'axios'
import { getToken } from './local_storage/login.storage'

export const baseURL = process.env.GATSBY_BASE_API_URL
let instance: AxiosInstance

export const baseAxios = axios.create({ baseURL })

export interface AppError extends Error {
  message: string,
  status: string,
  name: string
}

const BasicNetworkError = {
  message: 'It appears you are offline. Check your network connection and try again later.',
  status: 'It appears you are offline. Check your network connection and try again later.',
  name: 'NetworkError',
} as AppError

export const createNewInstance = (): AxiosInstance => {
  const { CancelToken } = axios
  const source = CancelToken.source()
  instance = axios.create({
    baseURL,
    cancelToken: source.token,
  })

  // Add a request interceptor
  instance.interceptors.request.use(async (config) => {
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${getToken()}`
    return config
  }, (error) => {
    // Do something with request error
    return Promise.reject(error)
  })

  instance.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.data
  }, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (!error.status && !error.response) {
      throw BasicNetworkError
    }
    if (!axios.isCancel(error)) {
      const { data } = error.response
      let { err } = data
      if (err)err.status = `${err.name}: ${data.message}`
      else err = { status: data }
      return Promise.reject(err)
    }
    return Promise.reject(error)
  })
  return instance
}

export default instance || createNewInstance()
