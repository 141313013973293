import React, { ReactElement } from 'react'
import { makeStyles, CircularProgress, ThemeProvider } from '@material-ui/core'
import clsx from 'clsx'
import { defaultTheme } from '../../styles/theme'


const useStyles = makeStyles(() => ({
  splashPage: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    zIndex: 10000,
    top: 0,
    left: 0,
    backgroundColor: defaultTheme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  splashGone: {
    display: 'none',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: defaultTheme.spacing(4),

  },
  progress: {
    color: '#FFF',
  },
  logo: {
    width: '256px',
  },
  loginText: {
    marginTop: defaultTheme.spacing(8),
    marginBottom: defaultTheme.spacing(4),
    color: defaultTheme.palette.primary.contrastText,
    textAlign: 'center',
  },
}))
export const ThemeLoadingComponent = (): ReactElement => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={defaultTheme}>
      <main className={clsx(classes.splashPage)}>
        <div className={classes.logoSection}>
          <CircularProgress size={200} className={classes.progress} />
        </div>
      </main>
    </ThemeProvider>
  )
}
