import React, { ReactElement, ReactNode, useState, useEffect, useContext } from 'react'
import { ThemeProvider } from '@material-ui/core'
import LayoutComponent from '../layout.component'
import { MessageContextProvider } from '../../contexts/message.store'
import { GlobalContextProvider, GlobalContext } from '../../contexts/global.store'
import { getOrganizationTheme } from '../../styles/theme'
import { ThemeLoadingComponent } from './theme-loading.component'
import ErrorBoundary from '../error-boundary.component'
import polyfills from '../../utils/polyfills'

type ContextProps = {
  children: ReactNode
  hasLayout?: boolean
}

export function AppComponent({ children, hasLayout = true }: ContextProps): ReactElement {
  const [error, setError] = useState<Error>()
  const AppContent = (): ReactElement => {
    const [globalState, globalDispatch] = useContext(GlobalContext)

    const getTheme = async (): Promise<void> => {
      const urlParams = polyfills.URLSearchParams(window.location.search)
      const themeId = urlParams.get('themeId')

      try {
        const orgTheme = await getOrganizationTheme(themeId)
        globalDispatch({ type: 'SET_THEME', theme: orgTheme })
      } catch (err) {
        setError(err)
        throw err
      }
    }

    useEffect(() => {
      getTheme()
    }, [])

    const layout = (): ReactElement => {
      if (hasLayout) return <LayoutComponent>{children}</LayoutComponent>
      return (<>{children}</>)
    }

    return (
      <>
        {globalState.theme ? (
          <ThemeProvider theme={globalState.theme.theme}>
            <MessageContextProvider>
              {layout()}
            </MessageContextProvider>
          </ThemeProvider>
        ) : <ThemeLoadingComponent />}
      </>
    )
  }

  return (
    <ErrorBoundary error={error} setError={setError}>
      <GlobalContextProvider>
        <AppContent />
      </GlobalContextProvider>
    </ErrorBoundary>
  )
}
