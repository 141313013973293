const bucketUrl = process.env.GATSBY_LOGO_STORAGE_BUCKET

// handles cases when themeId is provided and when no theme is provided
export const getLogoUrl = (themeId = 'app'): string => {
  return `${bucketUrl}/${themeId}/logo.png`
}

// handles case when a themeId is provided but no logo resource is available (used img onerror)
export const getDefaultLogoUrl = (): string => {
  return `${bucketUrl}/app/logo.png`
}

export const getWordLogoUrl = (themeId = 'app'): string => {
  return `${bucketUrl}/${themeId}/logowords.png`
}

export const getDefaultWordLogoUrl = (): string => {
  return `${bucketUrl}/app/logowords.png`
}
