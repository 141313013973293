import { ThemeOptions, Theme } from '@material-ui/core'
import { Base } from './base'

export type LogoStyles = {
  height?: string
}

export interface OrganizationTheme extends Base {
  logo_styles: LogoStyles
  primary_light: string
  primary_main: string
  primary_dark: string
  primary_contrast_text: string
  secondary_light: string
  secondary_main: string
  secondary_dark: string
  secondary_contrast_text: string
}

export interface AppLogos {
  logo: string
  logowords: string
}

export interface AppThemeOptions {
  themeId?: string,
  logo: LogoStyles,
  themeOptions: ThemeOptions,
}

export interface AppTheme {
  themeId: string,
  logo: LogoStyles,
  theme: Theme
}

export const toAppThemeOptions = (organizationTheme: OrganizationTheme, themeId: string): AppThemeOptions => {
  return {
    themeId,
    logo: organizationTheme.logo_styles,
    themeOptions: {
      palette: {
        primary: {
          light: organizationTheme.primary_light,
          main: organizationTheme.primary_main,
          dark: organizationTheme.primary_dark,
          contrastText: organizationTheme.primary_contrast_text,
        },
        secondary: {
          light: organizationTheme.secondary_light,
          main: organizationTheme.secondary_main,
          dark: organizationTheme.secondary_dark,
          contrastText: organizationTheme.secondary_contrast_text,
        },
        background: {
          default: '#FFF',
        },
      },
    },
  }
}
